.shopMainBox {
  background-color: #f1f0f1;
  display: flex;
  flex-direction: row;
  max-height: auto;
  padding-bottom: 5%;
}
.bigTextProductPage {
  font-size: 100%;
  font-weight: 600;
  color: black;
}
.vehicleText:hover {
  color: #fcc701;
}
.checkBoxCC {
  width: 15px;
  height: 15px;
  color: "black";
  margin-top: 10px;
  margin-right: 10px;
}
.selectBtn {
  color: white;
  background-color: #fec700;
  text-decoration: none;
  padding: 3% 3%;
  width: 50%;
  border: 1px solid white;
  margin: auto;
  text-align: center;
}
.selectOption {
  width: 80%;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 90%;
}
.selectOption::placeholder {
  color: black;
}
.shopContainer {
  /* width: 95%; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2%;
  /* border: 1px solid blue; */
}

.cancleBtn {
  font-size: 15;
  color: black;
  font-weight: 400;
  cursor: pointer;
  padding: 1% 5%;
  width: 60px;
}
.cancleBtn:hover {
  background-color: #fcc701;
  border-radius: 10px;
  padding: 1% 5%;
  width: 60px;
}

 

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .shopContainer {
    margin-left: 50px;
    grid-template-columns: repeat(2, 1fr);
  }
  .bigTextProductPage {
    font-size: 80%;
  }
  .filterContainer {
    margin-left: -5%;
  }
  .selectOption {
    font-size: 80%;
    width: 150%;
  }
}

@media only screen and (max-width: 767px) {
  .shopContainer {
    width: 120%;
    margin-left: 7%;
    padding-bottom: 150px;
    grid-template-columns: repeat(1, 1fr);
  }
  .bigTextProductPage {
    font-size: 30%;
  }
  .filterContainer {
    display: none;
  }
  .selectOption {
    font-size: 80%;
    width: 150%;
  }
  .shopMainBox {
    margin-top: 37%;
    padding-bottom: 20%;
  }
}
