.tableBox {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.boxborder {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #FEC324;
}

img {
  display: block;
  margin: 10px 0;
}

/* td, */
th {
  border: 1px solid #000;
  padding: 20px;
}

.card-title {
  margin-bottom: 20px;
}

.card {
  padding: 20px;
  margin: 20px 0;
}

.btn {
  margin: 20px;
}
