.user-detail-table {
  width: 100%;
  border-collapse: collapse;
}

.user-detail-table th,
.user-detail-table td {
  border: 1px solid #dddddd;
  padding: 8px;
}

.user-detail-table th {
  background-color: #f2f2f2;
  text-align: left;
}
